import { useEffect, useState } from 'react';
import List from "../Components/List";
import { pb } from "../Components/PocketBase";

function Search(){
	const [inputText, setInputText] = useState("");
	const [personajes, setPersonajes] = useState([]);
	const [universos, setUniversos] = useState([]);

	useEffect(() => {
		fetchUniversos();
		fetchPersonajes();
	}, []);

	const fetchUniversos = async () => {
		try{
		  const universos_request = await pb.collection("universos").getFullList();
		  setUniversos(universos_request);
		}catch (error){
		  console.error("Error fetching universo: ", error);
		}
	}

	const fetchPersonajes = async () => {
		try{
		  const personajes_request = await pb.collection("personajes").getFullList();
		  setPersonajes(personajes_request);
		}catch (error){
		  console.error("Error fetching personajes: ", error);
		}
	}

	return (
		<div className="search">
			<div className="search-spacer"/>
			Buscar<br/>
			<div className="search-bar">
				<input style={{width: "100%"}} onChange={e => {setInputText(e.target.value.toLowerCase())}}/>
			</div>
			<div className="search-list">			
				<List input={inputText} personajes={personajes} universos={universos}/>
			</div>
		</div>
	)
}

export default Search;