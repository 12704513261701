import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { pb } from "../Components/PocketBase"
import SuccessfullSubmit from "../Components/SuccessfullSubmit"
import { useCookies } from 'react-cookie';
import "./Pages.css";

//------------- Image slider  ---------------------------
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const spanStyle = {
  padding: '20px',
  background: '#efefef',
  color: '#000000'
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '400px',
	gridColumn: '1 / 3',
	maxHeight: '40vh',
	maxWidth: '60vw',
}

//---------------------------------------------------

function PersonajePage() {
  const { id } = useParams();
  const [personaje, setPersonaje] = useState();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

	const [error, setError] = useState("");

  const [cookies, setCookie, removeCookie] = useCookies(["invitado_id"]);

  const navigate = useNavigate();

  const getUserCurrentPersonajeId = () => {
    return cookies.invitado_personaje_id;
  }

  const fetchPersonaje = async () => {
    try{
      const personaje_request = await pb.collection("personajes").getOne(id);
      if(personaje_request && personaje_request.invitado !== "") navigate("/")

      console.log(personaje_request)
      setPersonaje(personaje_request);
    }catch (error){
      console.error("Error fetching categoria: ", error);
    }
  }

  useEffect(() => {
    fetchPersonaje();

  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
	
	if (!name || !lastName || !password){
			setError("¡Las casillas no pueden estar vacias!")
		return
	}

    const invitado_data = {
      "name": name,
      "lastName": lastName,
      "password": password
    }

    console.log(invitado_data);

    try{
      fetchPersonaje();
      if(personaje.invitado) throw new Error("Ese personaje ya ha sido seleccionado")
      
      const invitado = await pb.collection('invitados').create(invitado_data);

      const personaje_data = {
          "invitado": invitado.id
      };

      const personaje_update = await pb.collection('personajes').update(id, personaje_data);
      setSubmittedSuccessfully(true);
      setPersonaje(personaje_update)
    }catch (error){
      console.error("Error creating invitado: ", error);
    }
  }

  const modificarPersonaje = async () => {
    // Al personaje previamente seleccionado desasociarlo
    console.log(cookies.invitado_id)
    const invitadoCurrentPersonaje = await pb.collection("personajes").getList(1, 1, {
      filter: 'invitado.id = "' + cookies.invitado_id + '"'
    })

    const old_personaje_data = {
        "invitado": null
    };

    console.log(invitadoCurrentPersonaje.items[0].id)
    const old_personaje_update = await pb.collection('personajes').update(invitadoCurrentPersonaje.items[0].id, old_personaje_data);

    // Asociar al nuevo personaje
    const new_personaje_data = {
        "invitado": cookies.invitado_id
    };

    const new_personaje_update = await pb.collection('personajes').update(id, new_personaje_data);

    setSubmittedSuccessfully(true);
  }

  if(submittedSuccessfully) return <SuccessfullSubmit 
    invitado_name={cookies.invitado_id ? cookies.invitado_name : name} 
    personaje={personaje} 
    modificacion={cookies.invitado_id? true : false}
  ></SuccessfullSubmit>

  return (
    <div className="personaje-grid">
      <div className="personaje-h"><h1>Registrate</h1></div>

      <div className="personaje-p">¡Aparta el disfraz de {personaje ? personaje.name : <></>}!</div>
      {personaje && personaje.image.length > 1?
        <div className="personaje-slider">
            <Slide>
            {personaje.image.map((slideImage, index)=> (
                <div key={index} className="personaje-image">
					<img src={process.env.REACT_APP_DATABASE_URL + "/api/files/personajes/" + personaje.id + "/" + slideImage} alt="IMG"/>
				</div>
              ), personaje)} 
            </Slide>
        </div>
        :
        <div className="personaje-image"><img src={personaje ? process.env.REACT_APP_DATABASE_URL + "/api/files/personajes/" + personaje.id + "/" + personaje.image : ""} alt="IMG"/></div>
      }

      <div className="personaje-form">
      {
        cookies.invitado_id ?
        <button className="body-button" onClick={() => {modificarPersonaje()}}>Modificar personaje</button>
        :
        <div className="form-grid">
          <form className="form-grid" onSubmit={(e) => handleSubmit(e)}>
			<a style={{color: "red"}}>{error}</a>
            <a>Nombre     </a> <input name='name' type='text' value={name} onChange={e => setName(e.target.value)}/>
            <a>Apellido   </a> <input name='last-name' type='text' value={lastName} onChange={e => setLastName(e.target.value)}/>
            <a>Contraseña</a> <input className="form-password" name='password' type='password' value={password} onChange={e => setPassword(e.target.value)}/>
            <div className="indication">Te recomendamos poner como contraseña tu fecha de cumpleaños, por ejemplo, si cumples el 10 de julio pondrias 1007</div>
            <input className="invitado-submit" type="submit" value="Registrarse"></input>
          </form>
        </div>
      }
      </div>
    </div>
  );
}

export default PersonajePage;

