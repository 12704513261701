import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import './Components.css';
import { pb } from "./PocketBase.js"

function NavBar() {
  const [categorias, setCategorias] = useState([]);

  const [cookies, setCookie, removeCookie] = useCookies(['invitado_id']);

  useEffect(() => {
    fetchCategorias();
  }, []);

  const fetchCategorias = async () => {
    try{
      var categorias_request = await pb.collection("categorias").getFullList({
        sort: '-created'
      })
      setCategorias(categorias_request);
    }catch (error){
      console.error("Error fetching categorias: ", error);
    }
  }

  const logOut = () => {
    deleteAllCookies();
  }

  const deleteAllCookies = () => {
    removeCookie("invitado_id");
    removeCookie("invitado_name");
    removeCookie("useinvitado_lastName");
    removeCookie("invitado_personaje_id");
  }

  return (
    <div className="NavBar">
      <ul className="NavBar">
        <li>
          <div className="navbar-element"><NavLink to="/Search">
          <img style={{"height": "2.1vh", width: "auto"}} src="../magnifying-glass.png" alt="Search"></img>
          </NavLink></div>
        </li>
        <li><div className="navbar-element">
        {
          cookies.invitado_id ?
          <NavLink onClick={logOut}>Log out</NavLink>
          :
          <NavLink to="/LogIn">Modificar</NavLink>
        }
        </div></li>
        <li className="subnav">
          <div className="navbar-element"><NavLink to="/Categorias">Categorias</NavLink></div>
          <div className="subnav-content">
            {
              categorias.map((categoria, index) => 
                <div className="subnav-element" key={index}>                
                  <NavLink to={"/Categoria/" + categoria.id}>
                    <div style={{display: "inline-block", width: "100%"}}>{categoria.name}</div>
                  </NavLink>
                </div>
              )
            }
          </div>
        </li>
        <li><div className="navbar-element"><NavLink to="/">Home</NavLink></div></li>
      </ul>
    </div>
  );
}

export default NavBar;