function SubRecuadro(props){
	const records = props.records;

	return (
		<div className="sub-imgs">
			<img className="sub-img1" src={process.env.REACT_APP_DATABASE_URL + "/api/files/" + props.collection + "/" + records[0].id + "/" + records[0].image} alt="Imagen personaje 1"/>
			<img className="sub-img2" src={process.env.REACT_APP_DATABASE_URL + "/api/files/" + props.collection + "/" + records[1].id + "/" + records[1].image} alt="Imagen personaje 2"/>
			<img className="sub-img3" src={process.env.REACT_APP_DATABASE_URL + "/api/files/" + props.collection + "/" + records[2].id + "/" + records[2].image} alt="Imagen personaje 3"/>
			<img className="sub-img4" src={process.env.REACT_APP_DATABASE_URL + "/api/files/" + props.collection + "/" + records[3].id + "/" + records[3].image} alt="Imagen personaje 4"/>
		</div>
	)
}

export default SubRecuadro;
