import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { pb } from "../Components/PocketBase"
import "./Pages.css"

function LogIn(){
	const [name, setName] = useState();
	const [password, setPassword] = useState();

	const navigate = useNavigate();

	// Indicates error at login
	const [error, setError] = useState("");

	const [cookies, setCookie, removeCookie] = useCookies(['invitado_id', "invitado_name", "invitado_lastname"]);
	
	const saveUser = (invitado) => {
		setCookie("invitado_id", invitado.id);
		setCookie("invitado_name", invitado.name);
		setCookie("useinvitado_lastName", invitado.lastName);
	}

	const handleSubmit = async e => {
		e.preventDefault()

		if(!(name && password)){
			setError("Ingrese su nombre de usuario y contrasenia, por favor.")
			return
		}

		try{
			const invitado = await pb.collection("invitados").getFirstListItem(`name="${name}" && password="${password}"`)
			if (invitado){
				saveUser(invitado);
				setError("")
				navigate("/Categorias")
			}
			else{
				setError("El usuario no fue encontrado")
				console.log("User not found")
			}
		}catch (error){
			setError("El usuario no fue encontrado")
			console.log("Error at login")
		}
	
	}

	return(
		<div>
			<div className="top">
				<h1>Modifcar personaje</h1>
				<a>Aqui puedes iniciar una sesion para modificar tu personaje.</a>
			</div>

			<div className="recolocar-form">
				<form className="login-form" onSubmit={handleSubmit}>
					{error ? <a style={{color: "red"}}>{error}</a> :<></>}
					<a>Nombre		</a><input onChange={e => {setName(e.target.value)}} type="text"/>
					<a>Contrasenia	</a><input onChange={e => {setPassword(e.target.value)}} type="password"/>
					<button className="body-button">Log in</button>
				</form>
			</div>
		</div>
	)
}

export default LogIn;
