import { NavLink } from "react-router-dom";
import { useEffect, useState } from 'react';
import { pb } from "./PocketBase";
import "./Components.css";
import SubRecuadro from "./SubRecuadro"

function Categoria(props) {
  const [randomUniversos, setRandomUniversos] = useState([])

  useEffect(() => {
    fetchImages();
  }, [props.categoria.id]);

  const fetchImages = async () => {
    try{
      const randomUniversos_request = await pb.collection('universos').getList(1, 4, {
        filter: 'categoria.id = "' + props.categoria.id + '" && image != ""',
		sort: "@random",
        requestKey: props.categoria.id
      })

      setRandomUniversos(randomUniversos_request.items)
    }catch (error){
      console.log("Error fetching images for categorias:", error)
    }
  }

  return (
    <NavLink to={"/Categoria/" + props.categoria.id}>
      {/* <Recuadro parent={props.categoria} sub_imgs={randomUniversos} sub_col="universos" parent_col="categorias"></Recuadro> */}

      <div className="recuadro">
        {/* <img className="categoria" src="./FondoBoda1.jpeg" alt="IMG"/> */}
          <div className="recuadro-img-space">
          {
          randomUniversos.length >= 4?
          <SubRecuadro records={randomUniversos} collection="universos"></SubRecuadro>
          :
          <></>
        }

        </div>
        <div className="shade"></div>
        <div className="label"><a>{props.categoria.name}</a></div>
      </div>
    </NavLink>
  );
}

export default Categoria;
