function SuccessfullSubmit(props){
	return (
		<div className="center">
			{
				props.modificacion ?
				<>
				{props.invitado_name} hemos actualizado tu personaje a {props.personaje.name}.
				</>
				:
				<>
					Gracias {props.invitado_name} ya tenemos tu disfraz de {props.personaje.name} registrado. 
					Si gustas cambiarlo lo podras hacer en el login de la barra superior (utiliando el nombre y contrasenia ingresados).
				</>
			}
		</div>
	)	
}

export default SuccessfullSubmit;