import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import Universo from "../Components/Universo.js"
import { pb } from "../Components/PocketBase.js"; 

function CategoriaPage() {
  const { id } = useParams();
  const [categoria, setCategoria] = useState({});
  const [universos, setUniversos] = useState([]);

  useEffect(() => {
    fetchCategoria();
    fetchUniversos();
  }, [id]);

  const fetchCategoria = async () => {
    try{
      const categoria_request = await pb.collection("categorias").getOne(id);
      setCategoria(categoria_request);
    }catch (error){
      console.error("Error fetching categoria: ", error);
    }
  }

  const fetchUniversos = async () => {
    try{
      const universos_request = await pb.collection("universos").getFullList({
        //sort: '@random',
        filter: 'categoria.id = "' + id + '"'
      })
      setUniversos(universos_request);
    }catch (error){
      console.error("Error fetching universos: ", error);
    }
  }

  return (
    <div>
      <div className="top">
        <h1>{categoria.name ? categoria.name.toUpperCase() : <></>}</h1>
        <a>Seleccione el {categoria.name ? categoria.name.toLowerCase() : <></>} de su preferencia.</a>
      </div>

      <div className="recuadros_container">
        { universos?
          universos.map((universo, index) => 
            <Universo key={index} universo={universo}/>
          )
          :
          <></>
        }
      </div>
    </div>
  );
}

export default CategoriaPage;
