import { NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';
import { pb } from "./PocketBase"
import "./Components.css";
import Recuadro from "./Recuadro.js";

function Universo(props) {
  const [randomPersonajes, setRandomPersonajes] = useState([])

  useEffect(() => {
    fetchImages();
  }, [props.universo.id]);

  const fetchImages = async () => {
    try{
      const randomPersonajes_request = await pb.collection('personajes').getList(1, 4, {
        filter: 'universo.id = "' + props.universo.id + '" && image != ""',
		sort: "@random",
        requestKey: props.universo.id
      })

      setRandomPersonajes(randomPersonajes_request.items)
    }catch (error){
      console.log("Error fetching images for universos:", error)
    }
  }

  return (
    <NavLink to={"/Universo/" + props.universo.id}>
      <Recuadro parent={props.universo} sub_imgs={randomPersonajes} sub_col="personajes" parent_col="universos"></Recuadro>
    </NavLink>
  );
}

export default Universo;
