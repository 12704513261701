import './App.css';
import NavBar from "./Components/NavBar.js"
import Main from "./Pages/Main.js"

function App() {
  return (
    <div className="App">
      <Main/>
      <NavBar/>
    </div>
  );
}

export default App;
