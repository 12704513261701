import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import Personaje from "../Components/Personaje.js"
import { pb } from "../Components/PocketBase.js"; 

function UniversoPage() {
  const { id } = useParams();
  const [universo, setUniverso] = useState({});
  const [personajes, setPersonajes] = useState([]);

  useEffect(() => {
    fetchUniverso();
    fetchPersonajes();
  }, [id]);

  const fetchUniverso = async () => {
    try{
      const universo_request = await pb.collection("universos").getOne(id);
      setUniverso(universo_request);
    }catch (error){
      console.error("Error fetching universo: ", error);
    }
  }

  const fetchPersonajes = async () => {
    try{
      const personajes_request = await pb.collection("personajes").getFullList({
        sort: '@random',
        filter: 'universo.id = "' + id + '"'
      })

		var personajes_ordered = []

		for (let personaje of personajes_request){
			if (personaje.invitado) {
				personajes_ordered.push(personaje)
				continue;
			}
			personajes_ordered.unshift(personaje)
		} 

      setPersonajes(personajes_ordered);
    }catch (error){
      console.error("Error fetching personajes: ", error);
    }
  }

  return (
    <div>
      <div className="top">
        <h1>{universo.name ? universo.name : <></>}</h1>
        <a>Seleccione el personaje de {universo.name ? universo.name : <></>} de su preferencia.</a>
      </div>

      <div className="recuadros_container">
        {
          personajes.map((personaje, index) => 
            <Personaje key={index} personaje={personaje}/>
          )
        }
      </div>
    </div>
  );
}

export default UniversoPage;
