import { NavLink } from 'react-router-dom';
import { useState } from "react";
import "./Pages.css"
import { pb } from "../Components/PocketBase"

function Home() {
	
  const [name, setName] = useState("");
  const [celular, setCelular] = useState("");
  const [character, setCharacter] = useState("");
  const [universo, setUniverso] = useState("");
  const [submittedMessage, setSubmittedMessage] = useState("");
	const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
	
	if (!name || !celular || !character || !universo){
			setError("¡Las casillas no pueden estar vacias!")
		return
	}

    const solicitud_data = {
      "name": name,
      "character": character,
      "universo": universo,
		"celular": celular
    }

    console.log(solicitud_data);

    try{
      const solicitud_personaje = await pb.collection('solicitudes').create(solicitud_data);

		setSubmittedMessage("La informacion se ha registrado con exito.");
		setName("")
		setCelular("")
		setCharacter("")
		setUniverso("")
		setError("")
    }catch (error){
      console.error("Error fetching categoria: ", error);
    }
  }

  return (
    <div className="home">
		<div className="home-img-space" style={{backgroundImage: "url(./FondoBoda2.jpeg)"}}>
                                                                                        
        <div className="center">                                                  
          <h1 className="title">BODA DE <br/> OLIVIA & HECTOR</h1>                      
                                                                                        
          <a className="text">Seleccion de disfraz</a>                                  
          <br/>                                                                         
          <NavLink to="/Categorias">                                                    
            <button className="body-button"><a>Comenzar</a></button>                    
          </NavLink>                                                                    
        </div>                                                                          

		<div className="home-description">
  			<div className="page-objetivo">
  			  <h1>Objetivo</h1>
  			  <p>
				Esta página está diseñada para ayudar a coordinar los disfraces de los invitados. Les sugerimos que toda la familia elija disfraces grupales para hacerlo aún más divertido.
			</p>         
 			 </div>

			<div className="register">

			<div className="register-seleccion-image" style={{backgroundImage: "url(./seleccion-ejemplo.jpeg)"}}></div>

			<div className="register-instruction">
				<h1>¿Cómo registrarme?</h1>
					<p>
						Para elegir tu disfraz, simplemente haz clic en el personaje de tu preferencia e ingresa tu información. Recuerda guardar tu contraseña, ya que la necesitarás si deseas modificar tu selección más adelante.
					</p>
			</div>

			<div className="register-new-personaje">
				<h1>¿No está el personaje que buscas?</h1>
					<p>
					Ingresa tu información a continuación. Si tu sugerencia es aceptada, te enviaremos un mensaje de confirmación.
					</p>
			</div>

        <div className="register-form-grid">
          <form className="form-grid" onSubmit={(e) => handleSubmit(e)}>
			<a style={{color: "red"}}>{error}</a>
			<a style={{color: "green"}}>{submittedMessage}</a>
            <a>Nombre     </a> <input name='name' type='text' value={name} onChange={e => setName(e.target.value)}/>
            <a>Celular	</a> <input name='celular' type='text' value={celular} onChange={e => setCelular(e.target.value)}/>
            <a>Nombre del personaje </a> <input name='character' type='text' value={character} onChange={e => setCharacter(e.target.value)}/>
            <a>Serie o pelicula donde aparece</a> <input name='universo' type='text' value={universo} onChange={e => setUniverso(e.target.value)}/>
            <div className="indication">Te recomendamos poner como contraseña tu fecha de cumpleaños, por ejemplo, si cumples el 10 de julio pondrias 1007</div>
            <input className="invitado-submit" type="submit" value="Enviar"></input>
          </form>
        </div>

			</div>
		</div>

		</div>
    </div>
  );
}

export default Home;
