import { useContext, useEffect, useState } from 'react';
import Categoria from "../Components/Categoria.js"
import { pb } from "../Components/PocketBase.js"; 

function Categorias() {
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try{
      var categorias_request = await pb.collection("categorias").getFullList({
        sort: '@random',
        requestKey: null
      })
      setCategorias(categorias_request);
    }catch (error){
      console.error("Error fetching data: ", error);
    }
  }

  return (
    <div>
      <div className="top">
        <h1>Categorias</h1>
        Seleccione la categoria de su preferencia.
      </div>

      <div className="recuadros_container">
        {
          categorias.map((categoria, index) => 
            <Categoria key={index} categoria={categoria}/>
          )
        }
      </div>
    </div>
  );
}

export default Categorias;
