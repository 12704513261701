function Recuadro(props){
	const randomPersonajes = props.sub_imgs;
	const universos = props.parent_col;
	const personajes = props.sub_col;

	return (
		<div className="recuadro">
			{/* <img className="categoria" src="./FondoBoda1.jpeg" alt="IMG"/> */}
			<div className="recuadro-img-space">
			{
			  randomPersonajes.length >= 4?
			  <div className="show-animation">
			  <div className="sub-imgs">
			    <img className="sub-img1" src={process.env.REACT_APP_DATABASE_URL + "/api/files/" + personajes + "/" + randomPersonajes[0].id + "/" + randomPersonajes[0].image[0]} alt="Imagen personaje 1"/>
			    <img className="sub-img2" src={process.env.REACT_APP_DATABASE_URL + "/api/files/" + personajes + "/" + randomPersonajes[1].id + "/" + randomPersonajes[1].image[0]} alt="Imagen personaje 2"/>
			    <img className="sub-img3" src={process.env.REACT_APP_DATABASE_URL + "/api/files/" + personajes + "/" + randomPersonajes[2].id + "/" + randomPersonajes[2].image[0]} alt="Imagen personaje 3"/>
			    <img className="sub-img4" src={process.env.REACT_APP_DATABASE_URL + "/api/files/" + personajes + "/" + randomPersonajes[3].id + "/" + randomPersonajes[3].image[0]} alt="Imagen personaje 4"/>
			  </div>
			  </div>
			  :
			  <div className="sub-imgs">
			    <img className="sub-img1" src={process.env.REACT_APP_DATABASE_URL + "/api/files/" + universos + "/" + props.parent.id + "/" + props.parent.image} alt={"Imagen " + props.parent.name}/>
			  </div>
			}
			<img className="recuadro-img" src={process.env.REACT_APP_DATABASE_URL + "/api/files/" + universos + "/" + props.parent.id + "/" + props.parent.image} alt={"Imagen " + props.parent.name}/>

			{/* <div className="shade"></div> */}
			</div>
			<div className="shade"></div>
			<div className="label"><a>{props.parent.name}</a></div>
		</div>
	);
}

export default Recuadro;
