import { NavLink } from 'react-router-dom';

function List(props){
	var filteredUniversos = props.universos.filter((el) => {
		if(props.input === '') return el
		return el.name.toLowerCase().includes(props.input)

	})
	var filteredPersonajes = props.personajes.filter((el) => {
		if(el.invitado !== "") return
		if(props.input === '') return el

		return el.name.toLowerCase().includes(props.input)
	})

	return (
		<ul className="search-list">
			{
				filteredUniversos.map((universo) => 
					<li key={universo.id} className="search-list-universos">
						<NavLink to={"/Universo/" + universo.id}>
						<a>{universo.name}</a>
						</NavLink>
					</li>
				)
			}
			{
				filteredPersonajes.map((personaje) => 
					<li key={personaje.id} className="search-list-personajes">
					<NavLink to={"/Personaje/" + personaje.id}>
					<a>{personaje.name}</a>
					</NavLink>
					</li>
				)
			}
		</ul>
	)
}


export default List;