import { NavLink } from "react-router-dom";
import "./Components.css";

function Personaje(props) {
  var image = null;
  if (props.personaje.image.length <= 1){
    image = props.personaje.image
  }else{
    image = props.personaje.image[0]
  }

  if (props.personaje.invitado) return (
    <div className="taken-personaje">
      <div className="recuadro-img-space">
        <img className="personaje-img" src={process.env.REACT_APP_DATABASE_URL + "/api/files/personajes/" + props.personaje.id + "/" + image} alt="IMG"/>
        <div className="taken-shade"><a>OCUPADO</a></div>
      </div>
      <div className="label"><a>{props.personaje.name}</a></div>
    </div>
  );

  return (
    <NavLink to={"/Personaje/" + props.personaje.id}>
      <div className="recuadro">
        <div className="recuadro-img-space">
          <img className="personaje-img" src={process.env.REACT_APP_DATABASE_URL + "/api/files/personajes/" + props.personaje.id + "/" + image} alt="IMG"/>

        </div>
			
          <div className="shade"></div>
        <div className="label"><a>{props.personaje.name}</a></div>
      </div>
    </NavLink>
  );
}

export default Personaje;
