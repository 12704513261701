import { Routes, Route } from 'react-router-dom';
import Home from "./Home.js"
import Categorias from "./Categorias.js"
import CategoriaPage from "./CategoriaPage.js"
import UniversoPage from "./UniversoPage.js"
import PersonajePage from "./PersonajePage.js"
import Search from "./Search.js"
import LogIn from "./LogIn.js"

function Main() {
  return (
    <Routes>
      Main
      <Route path="/" element={<Home/>}></Route>
      <Route path="/Categorias" element={<Categorias/>}></Route>
      <Route path="/Categoria/:id" element={<CategoriaPage/>}></Route>
      <Route path="/Universo/:id" element={<UniversoPage/>}></Route>
      <Route path="/Personaje/:id" element={<PersonajePage/>}></Route>
      <Route path="/Search" element={<Search/>}></Route>
      <Route path="/LogIn" element={<LogIn/>}></Route>
    </Routes>
  );
}

export default Main;